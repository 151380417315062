<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="isLoggingIn"
          :indeterminate="isLoggingIn"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-text class="text-center">
          <v-avatar width="140" height="75" rounded="0" class="mb-4">
            <img :src="adminLogo" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            Sign in to your account
          </h6>

          <v-alert v-if="alert" color="red lighten-1" dark>
            {{ alertMessage }}
          </v-alert>

          <v-form
            ref="form"
            v-model="validForm"
            v-on:submit.prevent="formSubmit"
            lazy-validation
          >
            <v-text-field label="Email" v-model="email" :rules="emailRules" />
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :rules="nameRules"
              v-model="password"
              @click:append="show = !show"
            ></v-text-field>
            <v-btn
              v-if="$refs.form"
              :disabled="isLoggingIn"
              type="submit"
              color="primary"
              class="my-4"
              block
            >
              <v-icon left>mdi-login</v-icon>
              Sign In
            </v-btn>

            <div class="d-flex justify-around flex-wrap">
              <v-btn
                text
                small
                color="primary"
                class="mb-2"
                to="/auth/forgot-password"
                >Forgot Password</v-btn
              >
            </div>
          </v-form>
        </v-card-text>
        <v-snackbar v-model="snackbar" top color="danger">
          {{ this.getSnackbar }}
          <template v-slot:action="{ attrs }">
            <v-btn color="" text v-bind="attrs" @click="snackbar = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
import { objToUrlParam } from "@/shared/utils";
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Sign in",
  },
  data() {
    return {
      show: false,
      validForm: false,
      snackbar: false,
      email: null,
      password: null,
      alert: false,
      alertMessage: null,
      emailRules: [
        (v) => !!v || "Required.",
        (v) => /.+@.+\..+/.test(v) || "Invalid format.",
      ],
      nameRules: [(v) => !!v || "Required."],
    };
  },
  computed: {
    ...mapGetters([
      "loggedInUser",
      "getAuthLoading",
      "getAuthError",
      "getSnackbar",
      "getDomain",
    ]),
    adminLogo() {
      const payload = objToUrlParam({
        resource: get(this.getDomain, "logo_admin"),
        force: 1,
      });

      return `${process.env.VUE_APP_API_URL}/commands/thumbnail?${payload}`;
    },
    isLoggingIn() {
      return this.getAuthLoading || !_.isEmpty(this.loggedInUser);
    },
  },
  methods: {
    ...mapActions(["login"]),
    async formSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      await this.login({
        email: this.email,
        password: this.password,
        domainClientId: _.get(this.getDomain, "client_id"),
      });
    },
    showSnackbar(message, duration = 2000) {
      this.snackbar = true;
      this.snackbarMessage = message;
      this.snackbarTimeout = duration;
    },
  },
  watch: {
    loggedInUser(val) {
      if (val) {
        this.alert = false;
      }
    },
    getAuthError(val) {
      if (val != null) {
        this.alertMessage = val;
        this.alert = true;
      }
    },
    getSnackbar(val) {
      this.snackbar = !!val;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
